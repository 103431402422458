// src/utils/customCredentialsProvider.js

import { CognitoIdentity } from '@aws-sdk/client-cognito-identity';

const cognitoidentity = new CognitoIdentity({
    region: process.env.REACT_APP_AWS_REGION,
  });

class CustomCredentialsProvider {
    federatedLogin = null;
  
    loadFederatedLogin(login) {
      this.federatedLogin = login;
    }
  
    async getCredentialsAndIdentityId() {
      try {
        if (!this.federatedLogin) {
          throw new Error("Federated login information is not available.");
        }
  
        const getIdResult = await cognitoidentity.getId({
          IdentityPoolId: 'us-west-1:19bdbdfa-9874-48f3-93ce-64621f063b76',
          Logins: {
            [this.federatedLogin.domain]: this.federatedLogin.token
          },
        });
  
        const cognitoCredentialsResult = await cognitoidentity.getCredentialsForIdentity({
          IdentityId: getIdResult.IdentityId,
          Logins: {
            [this.federatedLogin.domain]: this.federatedLogin.token
          },
        });
  
        const credentials = {
          credentials: {
            accessKeyId: cognitoCredentialsResult.Credentials?.AccessKeyId,
            secretAccessKey: cognitoCredentialsResult.Credentials?.SecretKey,
            sessionToken: cognitoCredentialsResult.Credentials?.SessionToken,
            expiration: cognitoCredentialsResult.Credentials?.Expiration,
          },
          identityId: getIdResult.IdentityId,
        };
  
        return credentials;
      } catch (e) {
        console.log('Error getting credentials: ', e);
        throw e;
      }
    }
  
    clearCredentialsAndIdentityId() {
      // Clear any cached credentials and identityId here
    }
  }

export default CustomCredentialsProvider;