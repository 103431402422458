import { createClient } from '@supabase/supabase-js'

async function InitializeSupabaseClient(user) {
  try {
    const payload = {
      userId: user.sub,
      exp: Math.floor(Date.now() / 1000) + 24 * 60 * 60,
    };

    const { token: supaToken, error } = await GetSupaTokenAPI(payload);    
    if (error) throw error;

    const supabase = await createSupabaseClient(supaToken);
    console.log('supabase createclient data', supabase);

    if (!supabase) {
      throw new Error('Failed to create supabase client');
    }

    return { supabase, error: null };
  } catch (error) {
    console.log('Error retrieving Supabase session', error);
    return { awsSession: null, error };
  }
}

async function GetSupaTokenAPI( payload ) {
    try { 

      const response = await fetch('/api/signJWT', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error(`Error fetching signed JWT: ${response.statusText}`);
      }
  
      const data = await response.json();
      return { token: data.token, error: null };
    } catch (error) {
      console.log('Error fetching SupaToken:', error);
      return { token: null, error };
    }
  }

  async function createSupabaseClient( access_token ) {
    const options = {auth: {
      persistSession: true,
      autoRefreshToken: true
    }}
  
    if (access_token) {
      options.global = {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    }
    
    const supabase = createClient(
      process.env.REACT_APP_SUPABASE_URL,
      process.env.REACT_APP_SUPABASE_ANON_KEY,
      options
    )
  
    return supabase
  }

export default InitializeSupabaseClient;