import { useEffect } from 'react';
import { Navigate, BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import MainApp from './components/MainApp';

// This component handles the redirection after login
const AfterAuth = () => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/upload');
    }
  }, [isAuthenticated, navigate]);

  return null; // or a loading indicator
};

function App() {
  const ProtectedMainApp = withAuthenticationRequired(MainApp, {
    // This will redirect the user to the Auth0 login page
    onRedirecting: () => <div>Redirecting...</div>,
  });
  
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<ProtectedMainApp />} />
        <Route path="/upload" element={<ProtectedMainApp />} />
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/callback" element={<AfterAuth />} />
      </Routes>
    </Router>
  );
}

export default App;