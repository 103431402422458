import { useAuth0 } from '@auth0/auth0-react';
import {
    AppBar,
    Box,
    Button,
    Container,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    LinearProgress,
    Link,
    MenuItem,
    Select,
    Typography,
    Toolbar
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useRef, useState, useReducer } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Amplify } from 'aws-amplify';
import { uploadData } from 'aws-amplify/storage';
import InitializeCognitoSession from '../utils/createCognitoSession';
import InitializeSupabaseClient from '../utils/createSupabaseClient';
import config from '../config/amplifyconfiguration.json';

const amplifyConfig = {
    ...config,
    auth: {
      ...config.auth,
      plugins: {
        ...config.auth.plugins,
        awsCognitoAuthPlugin: {
          ...config.auth.plugins.awsCognitoAuthPlugin,
          Auth: {
            ...config.auth.plugins.awsCognitoAuthPlugin.Auth,
            Default: {
              ...config.auth.plugins.awsCognitoAuthPlugin.Auth.Default,
              OAuth: {
                ...config.auth.plugins.awsCognitoAuthPlugin.Auth.Default.OAuth,
                WebDomain: process.env.REACT_APP_AUTH0_DOMAIN,
                AppClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
                SignInRedirectURI: window.location.origin + '/callback',
                SignOutRedirectURI: window.location.origin + '/login',
              }
            }
          }
        }
      }
    }
  };
  
  Amplify.configure(amplifyConfig);


function MainApp() {
    const uploadTaskRef = useRef(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [progress, setProgress] = useState(0);
    const options = ['feature video', 'poster', 'subtitle'];
    const [selectedCategory, setSelectedCategory] = useState(options[0]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [assets, setAssets] = useState([]);
    const [selectedTitle, setSelectedTitle] = useState(null);
    const [currUploadFileName, setCurrUploadFileName] = useState('');
    const [currUploadCat, setCurrUploadCat] = useState('');
    const [fileBytes, setFileBytes] = useState(0);
    const [currCanceled, setCurrCanceled] = useState(false);
    const { user, isAuthenticated, getIdTokenClaims, logout } = useAuth0();
    const [fullyAuthenticated, setFullyAuthenticated] = useState(false);
    const [authError, setAuthError] = useState('');
    const [supabase, setSupabase] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [supaUserId, setSupaUserId] = useState('');
    const [noTitlesExist, setNoTitlesExist] = useState('');
    const [userIsActive, setUserIsActive] = useState(null);

    useEffect(() => {
        const fullyAuthenticate = async () => {
            if (isAuthenticated && user) {
                try {
                    const idTokenClaims = await getIdTokenClaims();
                    const idToken = idTokenClaims.__raw;
                    const { awsSession, error: awsError } = await InitializeCognitoSession(idToken);
                    if (awsError) console.error('Error getting aws session: ', awsError);

                    const { supabase, error: supabaseError } = await InitializeSupabaseClient(user);
                    if (supabaseError) console.error('Error getting supabase session: ', supabaseError);
                    console.log('supabase client: ', supabase);

                    let { data, error } = await supabase.rpc('getUserId');
                    if (error) console.error('Error getting Supabase Id: ', error);

                    if (!!awsSession && !!supabase && !!data) {
                        setFullyAuthenticated(true);
                        setSupabase(supabase);
                        setUserEmail(user.email);
                        setSupaUserId(data);
                        setAuthError('');
                    }
                    else {
                        setAuthError('not fully authenticated');
                    }

                } catch (error) {
                    console.error('Error in Cognito/Supabase authentication:', error);
                    setAuthError(error.message);
                }
            }
        };

        fullyAuthenticate();
    }, [isAuthenticated, user, getIdTokenClaims]);


    const handleSignOut = () => {
        logout({ returnTo: window.location.origin });
    };

    function handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setCurrUploadFileName('');
        }
    }

    const handleTitleSelection = useCallback(async (titleId) => {
        try {
            let { data: assetsData, error } = await supabase
                .from('assets')
                .select('*')
                .eq('title_id', titleId);

            if (error) throw error;
            setAssets(assetsData);
            setCurrUploadFileName('');
        } catch (error) {
            console.error('Error fetching assets:', error);
        }
    }, [supabase]);


    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    async function handleUpload(event) {
        if (!selectedFile) return;

        setUploadStatus('Uploading...');
        setIsUploading(true);
        setIsPaused(false);

        setCurrUploadCat(selectedCategory);
        setCurrUploadFileName(selectedFile.name);
        setProgress(0);
        setFileBytes(0);
        setCurrCanceled(false);

        const uploadTime = new Date().toISOString();

        // Step 1: Insert new record in assets table
        const { data: assetData, error: insertError } = await supabase
            .from('assets')
            .insert([{ name: selectedFile.name }])
            .single()
            .select();

        if (insertError) {
            console.error('Error inserting new asset:', insertError);
            setUploadStatus('Error in inserting new asset');
            setIsUploading(false);
            setIsPaused(false);
            return;
        }

        // Step 2: Record Asset ID
        const newAssetId = assetData.id;

        function formatKey(titleName, fileName) {
            const cleanCategory = selectedCategory.replace(/[^a-zA-Z0-9]/g, '_');
            const cleanTitleName = titleName.replace(/[^a-zA-Z0-9]/g, '_');
            const extension = fileName.slice(fileName.lastIndexOf('.'));
            const nameWithoutExtension = fileName.slice(0, fileName.lastIndexOf('.'));
            const cleanFileNameWithoutExtension = nameWithoutExtension.replace(
                /[^a-zA-Z0-9]/g,
                '_',
            );
            const cleanFileName = `${cleanCategory}_${cleanFileNameWithoutExtension}_${newAssetId}${extension}`;
            return `${cleanTitleName}_${selectedTitle.id}/${cleanFileName}`;
        }

        const formatted_key = formatKey(selectedTitle.name, selectedFile.name);

        try {
            Amplify.configure({
                ...Amplify.getConfig(),
                Storage: {
                    S3: {
                        region: 'us-west-1',
                        bucket: 'bizaar-temp'
                    }
                }
            });
            const uploadResponse = uploadData({
                key: formatted_key,
                data: selectedFile,
                options: {
                    onProgress: ({ transferredBytes, totalBytes }) => {
                        if (totalBytes) {
                            const percentage = Math.round(
                                (transferredBytes / totalBytes) * 100,
                            );
                            setProgress(percentage);
                            setFileBytes(totalBytes);
                            console.log(`Upload progress ${percentage} %`);
                        }
                    },
                },
            });

            uploadTaskRef.current = uploadResponse;

            const uploadResult = await uploadResponse.result;

            if (!uploadResult) {
                throw new Error('Upload failed');
            }

            // Step 4: Update Asset Record with URL and other details
            const { error: updateError } = await supabase
                .from('assets')
                .update({
                    title_id: selectedTitle.id,
                    s3_key: uploadResult.key,
                    category: selectedCategory,
                    name: selectedFile.name,
                    file_size: selectedFile.size
                })
                .eq('id', newAssetId);

            if (updateError) {
                throw new Error('Error updating asset record');
            }

            // Step 5: Update Asset List in UI
            setAssets([
                ...assets,
                {
                    id: newAssetId,
                    name: selectedFile.name,
                    url: uploadResult.key,
                    category: selectedCategory,
                    file_size: selectedFile.size,
                    created_at: uploadTime.toLocaleString()
                },
            ]);
            setUploadStatus('Upload Complete');
            setIsUploading(false);
            setIsPaused(false);
            setSelectedFile(null);
            setSelectedCategory(options[0]);
        } catch (error) {
            if (error.name !== 'CanceledError') {
                console.error('Error during upload: ', error);
                setUploadStatus('Error uploading file');
                setIsUploading(false);
                setIsPaused(false);
            }
            // Delete the newly created record if upload fails
            const { error: deleteError } = await supabase
                .from('assets')
                .delete()
                .eq('id', newAssetId);

            if (deleteError) {
                console.error('Error deleting asset record: ', deleteError);
            }
        }
    }

    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case 'SET_TITLES':
                return { ...state, titles: action.titles, isLoading: false };
            case 'SET_LOADING':
                return { ...state, isLoading: action.isLoading };
            default:
                return state;
        }
    }, {
        titles: null,
        isLoading: true,
    });

    const { isLoading, titles } = state;

    useEffect(() => {
        async function fetchData() {
            let isActive = false;

            if (supaUserId) {
                try {
                    let { data: userData, error: userError } = await supabase
                        .from('users')
                        .select('active')
                        .eq('id', supaUserId)
                        .single();

                    if (userError) throw userError;

                    isActive = userData.active;

                    if (!isActive) {
                        // User is not active; update state accordingly and halt further execution
                        setUserIsActive(false);
                        setNoTitlesExist(false); // Adjust based on your logic, might not be needed
                        dispatch({ type: 'SET_LOADING', isLoading: false });
                        return; // Stop execution if user is not active
                    }

                    // Proceed to fetch titles if user is active
                    let { data, error } = await supabase
                        .from('titles')
                        .select(`
                          id, 
                          name, 
                          user_titles!inner(
                            access_active, 
                            user_id,
                            users!user_titles_user_id_fkey(active)
                          )`)
                        .eq('user_titles.user_id', supaUserId)
                        .eq('user_titles.access_active', true);

                    if (error) throw error;

                    // Update state based on titles data
                    dispatch({ type: 'SET_TITLES', titles: data.length > 0 ? data : [] });
                    setNoTitlesExist(data.length === 0);
                    setUserIsActive(true);

                } catch (error) {
                    console.error("An error occurred:", error);
                    dispatch({ type: 'SET_LOADING', isLoading: false });
                }
            } else {
                // Handle case where supaUserId is not yet available
                dispatch({ type: 'SET_LOADING', isLoading: false });
                setNoTitlesExist(true);
            }
        }

        fetchData();
    }, [supaUserId, userEmail, supabase]);

    const selectTitleAndFetchAssets = useCallback(
        (titleId) => {
            const title = titles.find((m) => m.id === titleId);
            setSelectedTitle(title);
            if (title) {
                handleTitleSelection(titleId);
            }
        },
        [titles, handleTitleSelection],
    );

    useEffect(() => {
        if (titles && titles.length > 0) {
            selectTitleAndFetchAssets(titles[0].id);
        }
    }, [titles, selectTitleAndFetchAssets]);

    const handleTitleSelectionChange = (event) => {
        selectTitleAndFetchAssets(event.target.value);
    };

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 500,
            flex: 2,
            editable: false,
        },
        {
            field: 'category',
            headerName: 'Category',
            width: 20,
            flex: .5,
            editable: false,
        },
        {
            field: 'file_size',
            headerName: 'Size',
            width: 20,
            flex: .5,
            editable: false,
            valueGetter: (params) => formatBytes(params.row.file_size),
        },
        {
            field: 'created_at',
            headerName: 'Uploaded At',
            width: 200,
            valueGetter: (params) => new Date(params.row.created_at).toLocaleString(),
        },
    ];

    function LinearProgressWithLabel(props) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h5" style={{ flexGrow: 1 }}>
                        Bizaar File Uploader
                        <Typography variant="body2" style={{ fontStyle: 'italic', display: 'inline', marginLeft: '8px' }}>
                            v0.1.2
                        </Typography>
                    </Typography>
                    <Button color="inherit" onClick={handleSignOut}>
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>

            {/* Handle loading state */}
            {(!isAuthenticated || isLoading) && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            )}

            {/* Handle authentication error */}
            {authError && (
                <Typography variant="body1" sx={{ mt: 2 }}>
                    There was an error retrieving your information. Please logout and try again. If the problem persists, contact <Link href="mailto:support@bizaarstudios.com">support@bizaarstudios.com</Link> for assistance.
                </Typography>
            )}

            {/* Show user email if logged in and not loading */}
            {!authError && !isLoading && isAuthenticated && fullyAuthenticated && (
                <Typography variant="h6" sx={{ mt: 2 }} style={{ flexGrow: 1 }}>
                    Logged in as: {userEmail}
                </Typography>
            )}

            {/* Handle inactive account */}
            {!authError && !isLoading && isAuthenticated && fullyAuthenticated && userIsActive === false && (
                <Typography variant="body1" sx={{ mt: 2 }}>
                    Your account is currently inactive. Please contact <Link href="mailto:support@bizaarstudios.com">support@bizaarstudios.com</Link> for assistance.
                </Typography>
            )}

            {/* Handle no titles state */}
            {!authError && !isLoading && isAuthenticated && fullyAuthenticated && noTitlesExist && userIsActive === true && (
                <Typography variant="body1" sx={{ mt: 2 }}>
                    You do not have any active titles associated with your account. Please contact <Link href="mailto:support@bizaarstudios.com">support@bizaarstudios.com</Link> for assistance.
                </Typography>
            )}

            {/* Handle titles loaded state */}
            {!authError && !isLoading && isAuthenticated && fullyAuthenticated && titles && titles.length > 0 && (
                <>
                    <FormControl sx={{ mb: 2, mt: 3, minWidth: '600px' }}>
                        <InputLabel id="title-label">Select Title</InputLabel>
                        <Select
                            labelId="title-label"
                            value={selectedTitle ? selectedTitle.id : ''}
                            label="Select Title"
                            size="medium"
                            disabled={isUploading}
                            onChange={handleTitleSelectionChange}
                        >
                            {titles.map((title, index) => (
                                <MenuItem key={`title-${index}`} value={title.id}>
                                    {title.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Container sx={{ mx: 0, border: 1, borderColor: 'grey.400', borderRadius: '5px' }}>
                        {selectedTitle && (
                            <Typography variant="h4" sx={{ my: 2 }}>
                                {selectedTitle.name}
                            </Typography>
                        )}
                        <Typography variant="h5" sx={{ mt: 4, textDecoration: 'underline' }}>Existing Files</Typography>
                        {assets.length === 0 && (
                            <Typography variant="body1" sx={{ mt: 2, fontStyle: 'italic' }}>No existing files</Typography>
                        )}
                        {assets.length > 0 && (
                            <DataGrid
                                sx={{ border: 'none' }}
                                rows={assets}
                                columns={columns}
                                disableRowSelectionOnClick
                            />
                        )}
                    </Container>
                    <Container sx={{ pt: 3, my: 2, mx: 0, border: 1, borderColor: 'grey.400', borderRadius: '5px' }}>
                        <Typography variant="h5" sx={{ textDecoration: 'underline' }}>Add Files</Typography>
                        <Box sx={{ pb: 6 }}>
                            <Grid container sx={{ alignItems: 'center', mt: 2 }}>
                                <Grid xs={2} item>
                                    <Button variant="contained" component="label" disabled={isUploading}>
                                        Select File
                                        <input onChange={handleFileChange} type="file" hidden />
                                    </Button>
                                </Grid>
                                <Grid xs={6} item>
                                    {selectedFile && <Typography>{selectedFile.name}</Typography>}
                                </Grid>
                                <Grid xs={2} item>
                                    <FormControl fullWidth>
                                        <InputLabel id="category-label">Category</InputLabel>
                                        <Select
                                            labelId="category-label"
                                            value={selectedCategory}
                                            label="Category"
                                            size="small"
                                            disabled={isUploading}
                                            onChange={(e) => setSelectedCategory(e.target.value)}
                                        >
                                            {options.map((option, index) => (
                                                <MenuItem key={`cat-${index}`} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    xs={2}
                                    item
                                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                                >
                                    <Button variant="contained" onClick={handleUpload} disabled={isUploading}>
                                        Upload
                                    </Button>
                                </Grid>
                            </Grid>
                            {currUploadFileName && (
                                <Grid container sx={{ alignItems: 'center', mt: 4 }}>
                                    <Grid xs={4.5} item>
                                        <Typography>{currUploadFileName ?? ''}</Typography>
                                    </Grid>
                                    <Grid xs={1.5} item>
                                        <Typography>{currUploadCat}</Typography>
                                    </Grid>
                                    <Grid xs={1} item>
                                        <Typography>{formatBytes(fileBytes)}</Typography>
                                    </Grid>
                                    <Grid xs={2} item>
                                        <LinearProgressWithLabel variant="determinate" value={progress} />
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                        sx={{ display: 'flex', justifyContent: 'center' }}
                                    >
                                        {isUploading && (
                                            <>
                                                <IconButton
                                                    disabled={currCanceled || !isUploading}
                                                    onClick={async () => {
                                                        if (uploadTaskRef.current) {
                                                            await uploadTaskRef.current.cancel();
                                                            setCurrCanceled(true);
                                                            setUploadStatus('Cancelled');
                                                            setIsUploading(false);
                                                            setSelectedFile(null);
                                                            setSelectedCategory(options[0]);
                                                            setIsPaused(false);
                                                        }
                                                    }}
                                                >
                                                    <CancelIcon />
                                                </IconButton>
                                                <IconButton
                                                    disabled={currCanceled || isPaused || !isUploading}
                                                    onClick={() => {
                                                        if (uploadTaskRef.current && !currCanceled) {
                                                            uploadTaskRef.current.pause();
                                                            setUploadStatus('Paused');
                                                            setIsPaused(true);
                                                        }
                                                    }}
                                                >
                                                    <PauseCircleIcon />
                                                </IconButton>
                                                <IconButton
                                                    disabled={currCanceled || !isPaused || !isUploading}
                                                    onClick={() => {
                                                        if (uploadTaskRef.current && !currCanceled) {
                                                            uploadTaskRef.current.resume();
                                                            setUploadStatus('Uploading...');
                                                            setIsPaused(false);
                                                        }
                                                    }}
                                                >
                                                    <PlayCircleIcon />
                                                </IconButton>
                                            </>
                                        )}
                                    </Grid>
                                    <Grid
                                        xs={1}
                                        item
                                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                                    >
                                        <Typography>{uploadStatus}</Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Box>
                    </Container>
                </>
            )}
        </Container>
    );
}

export default MainApp;