import { Amplify } from 'aws-amplify';
import amplifyconfig from '../config/amplifyconfiguration.json';
import CustomCredentialsProvider from './customCredentialsProvider';
import { fetchAuthSession } from 'aws-amplify/auth';

async function InitializeCognitoSession(idToken) { 
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const customCredentialsProvider = new CustomCredentialsProvider();
  const amplifyConfig = amplifyconfig;
  Amplify.configure(amplifyConfig, {
    Auth: {
      credentialsProvider: customCredentialsProvider
    },
  });
  try {
    customCredentialsProvider.loadFederatedLogin({
      domain: domain,
      token: idToken
    });

    const awsSession = await fetchAuthSession();

    if (!awsSession || !awsSession.credentials || !awsSession.credentials.sessionToken) {
      throw new Error('AWS session token not found');
    }

    return { awsSession, error: null };
  } catch (error) {
    console.log('Error retrieving AWS session tokens', error);
    return { awsSession: null, error };
  }
}

export default InitializeCognitoSession;